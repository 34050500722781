var tableColumns = [{
  title: "ID",
  dataIndex: "manuscriptid",
  key: "manuscriptid",
  width: 140
}, {
  title: "创作者昵称",
  dataIndex: "creatorname",
  key: "creatorname",
  width: 120,
  scopedSlots: {
    customRender: "creatorname"
  }
}, {
  title: "视频渠道",
  dataIndex: "channel",
  key: "channel",
  width: 100,
  scopedSlots: {
    customRender: "channel"
  }
}, {
  title: "投稿任务",
  dataIndex: "taskname",
  key: "taskname",
  width: 100,
  scopedSlots: {
    customRender: "taskname"
  }
}, {
  title: "投稿任务广告主",
  dataIndex: "advertisername",
  key: "advertisername",
  width: 180,
  scopedSlots: {
    customRender: "advertisername"
  }
}, {
  title: "投稿时间",
  dataIndex: "submittime",
  key: "submittime",
  width: 180,
  scopedSlots: {
    customRender: "submittime"
  }
}, {
  title: "状态",
  dataIndex: "status",
  key: "status",
  width: 80,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
export { tableColumns };