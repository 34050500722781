import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "manuscriptRef"
  }, [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticClass: "action-btn-group"
  }, [_c("a-radio-group", {
    attrs: {
      size: "large",
      value: _vm.status
    },
    on: {
      change: _vm.handleTabChange
    }
  }, _vm._l(_vm.statusList, function (item, index) {
    return _c("a-radio-button", {
      key: index,
      attrs: {
        disabled: _vm.loading,
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + "(" + _vm._s(item.number) + ") ")]);
  }), 1)], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.tableColumns,
      rowKey: "manuscriptid",
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    on: {
      change: _vm.handleTableChange
    },
    scopedSlots: _vm._u([{
      key: "channel",
      fn: function fn(channel) {
        return _vm._l(_vm.checkChannel(channel), function (item, key) {
          return _c("a-tag", {
            key: key,
            staticStyle: {
              margin: "2px"
            },
            attrs: {
              color: "blue"
            }
          }, [_vm._v(_vm._s(item))]);
        });
      }
    }, {
      key: "creatorname",
      fn: function fn(creatorname, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.creatoruid, "userInfo");
            }
          }
        }, [_vm._v(_vm._s(creatorname))])];
      }
    }, {
      key: "taskname",
      fn: function fn(taskname, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.taskid, "taskDetails");
            }
          }
        }, [_vm._v(_vm._s(taskname))])];
      }
    }, {
      key: "advertisername",
      fn: function fn(advertisername, slot) {
        return [_c("span", {
          staticStyle: {
            color: "#1890ff",
            cursor: "pointer"
          },
          on: {
            click: function click($event) {
              return _vm.openNewPage(slot.advertiseruid, "userInfo");
            }
          }
        }, [_vm._v(_vm._s(advertisername))])];
      }
    }, {
      key: "submittime",
      fn: function fn(submittime) {
        return [_c("span", [_vm._v(_vm._s(_vm.fmtTimeVal(submittime)))])];
      }
    }, {
      key: "action",
      fn: function fn(action, item) {
        return [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["check:bcManuscriptManage:manuscriptDetails"],
            expression: "['check:bcManuscriptManage:manuscriptDetails']"
          }],
          staticStyle: {
            "min-width": "auto",
            padding: "0",
            margin: "0"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.showEditModal(item);
            }
          }
        }, [_vm._v("查看")])];
      }
    }])
  }, [_c("template", {
    slot: "status"
  }, [_vm._v(" " + _vm._s(_vm.formatTableField(_vm.status)) + " ")])], 2), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    staticClass: "modal-container taskDetails",
    staticStyle: {
      "max-height": "800px",
      overflow: "scroll"
    },
    attrs: {
      title: "视频投稿审核",
      width: 1000,
      footer: null,
      getContainer: function getContainer() {
        return _vm.$refs.manuscriptRef;
      },
      centered: ""
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm4",
    attrs: {
      rules: _vm.rules2,
      model: _vm.logItem,
      "label-col": {
        span: 6
      },
      "wrapper-col": {
        span: 14
      }
    }
  }, [_c("a-card", {
    staticStyle: {
      background: "rgb(240, 242, 245)"
    },
    attrs: {
      title: "视频信息"
    }
  }, [_c("div", {
    staticClass: "action-btn-group videoInfo-btn-group"
  }, [_c("a-radio-group", {
    attrs: {
      size: "large",
      value: _vm.videoType
    },
    on: {
      change: _vm.handleVideoTabChange
    }
  }, [_c("a-radio-button", {
    attrs: {
      disabled: _vm.loading,
      value: 0
    }
  }, [_vm._v(" KNOWHERE "), _c("a-icon", {
    attrs: {
      type: _vm.checkIcon("KNOWHERE")
    }
  })], 1), _c("a-radio-button", {
    attrs: {
      disabled: _vm.loading,
      value: 1
    }
  }, [_vm._v(" Twitter "), _c("a-icon", {
    attrs: {
      type: _vm.checkIcon("Twitter")
    }
  })], 1), _c("a-radio-button", {
    attrs: {
      disabled: _vm.loading,
      value: 2
    }
  }, [_vm._v(" Youtube "), _c("a-icon", {
    attrs: {
      type: _vm.checkIcon("Youtube")
    }
  })], 1)], 1)], 1), _c("a-descriptions", {
    attrs: {
      title: " ",
      column: 1,
      bordered: true
    }
  }, [_vm.videoType == 0 ? _c("a-descriptions-item", {
    attrs: {
      label: "视频ID"
    }
  }, [_vm._v(" " + _vm._s(_vm.fmtEmptyVal(_vm.logItem.knowherevideoid)) + " ")]) : _vm._e(), _c("a-descriptions-item", {
    attrs: {
      label: "视频标题"
    }
  }, [_vm._v(" " + _vm._s(_vm.videoType ? "" : _vm.fmtEmptyVal(_vm.logItem.knowherevideotitle)) + " ")]), _c("a-descriptions-item", {
    attrs: {
      label: "视频链接"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#1890ff",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.openNewPage(_vm.videoType == 0 ? _vm.logItem.knowherevideourl : _vm.videoType == 1 ? _vm.logItem.twittervideourl : _vm.logItem.youtubevideourl, "url");
      }
    }
  }, [_vm._v(_vm._s(_vm.fmtEmptyVal(_vm.videoType == 0 ? _vm.logItem.knowherevideourl : _vm.videoType == 1 ? _vm.logItem.twittervideourl : _vm.logItem.youtubevideourl)))])]), _vm.videoType != 0 ? _c("a-descriptions-item", {
    attrs: {
      label: "视频授权"
    }
  }, [_vm._v(" " + _vm._s(_vm.fmtEmptyVal(_vm.videoType == 1 ? _vm.logItem.twitterauthorize : _vm.logItem.youtubeauthorize)) + " ")]) : _vm._e()], 1)], 1), _c("a-card", {
    staticStyle: {
      background: "rgb(240, 242, 245)",
      "margin-top": "20px",
      "border-radius": "20px"
    },
    attrs: {
      title: "任务信息"
    }
  }, [_c("a-descriptions", {
    attrs: {
      title: " ",
      column: 1,
      bordered: true
    }
  }, [_c("a-descriptions-item", {
    attrs: {
      label: "创作者昵称"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#1890ff",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.openNewPage(_vm.logItem.creatoruid, "userInfo");
      }
    }
  }, [_vm._v(_vm._s(_vm.logItem.creatorname))])]), _c("a-descriptions-item", {
    attrs: {
      label: "投稿任务"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#1890ff",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.openNewPage(_vm.logItem.taskid, "taskDetails");
      }
    }
  }, [_vm._v(_vm._s(_vm.logItem.taskname))])]), _c("a-descriptions-item", {
    attrs: {
      label: "投稿广告主"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#1890ff",
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.openNewPage(_vm.logItem.advertiseruid, "userInfo");
      }
    }
  }, [_vm._v(_vm._s(_vm.logItem.advertisername))])]), _c("a-descriptions-item", {
    attrs: {
      label: "投稿时间"
    }
  }, [_vm._v(" " + _vm._s(_vm.fmtTimeVal(_vm.logItem.submittime)) + " ")]), _c("a-descriptions-item", {
    attrs: {
      label: "状态"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatTableField(_vm.status)) + " ")])], 1)], 1), _c("a-card", {
    staticStyle: {
      background: "rgb(240, 242, 245)",
      "border-radius": "20px",
      "margin-top": "20px"
    },
    attrs: {
      title: "审核结果"
    }
  }, [_c("a-radio-group", {
    model: {
      value: _vm.pasValue,
      callback: function callback($$v) {
        _vm.pasValue = $$v;
      },
      expression: "pasValue"
    }
  }, [_c("a-space", {
    attrs: {
      direction: "vertical"
    }
  }, [_c("a-radio", {
    attrs: {
      disabled: _vm.status != 0,
      value: 1
    }
  }, [_vm._v("通过")]), _c("a-radio", {
    attrs: {
      disabled: _vm.status != 0,
      value: 2
    }
  }, [_vm._v("驳回")]), _c("a-form-model-item", {
    attrs: {
      label: "",
      prop: "rejectMessage"
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "500px"
    },
    attrs: {
      disabled: _vm.status != 0 || _vm.pasValue != 2,
      size: "large",
      placeholder: "请输入拒绝原因",
      "auto-size": {
        minRows: 3,
        maxRows: 8
      }
    },
    model: {
      value: _vm.rejectMessage,
      callback: function callback($$v) {
        _vm.rejectMessage = $$v;
      },
      expression: "rejectMessage"
    }
  })], 1)], 1)], 1)], 1), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["check:bcManuscriptManage:submit"],
      expression: "['check:bcManuscriptManage:submit']"
    }],
    staticStyle: {
      "margin-top": "20px",
      width: "100%",
      height: "50px"
    },
    attrs: {
      type: "primary",
      loading: _vm.loading4,
      disabled: _vm.status != 0
    },
    on: {
      click: function click($event) {
        return _vm.approveAction(_vm.logItem);
      }
    }
  }, [_vm._v(" 提交 ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };